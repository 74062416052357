import { Component, OnInit , ViewEncapsulation, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
//import { extend } from '@syncfusion/ej2-base';
//import { extend, Internationalization, closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../_services/config.service';
import { AccountService } from '@app/_services';
import { DataService, PTOService } from '@app/_services';
import { takeUntil, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

import { PopupOpenEventArgs, TimeScaleModel, View, EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService, EventRenderedArgs, ScheduleComponent, ActionEventArgs, EJ2Instance } from '@syncfusion/ej2-angular-schedule';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
//import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import { Dialog } from '@syncfusion/ej2-angular-popups';

import { DialogEditEventArgs, SaveEventArgs, EditSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { FormGroup } from '@angular/forms';

const baseUrl = `${environment.apiUrl}`;
const directoryUrl = `${environment.directoryUrl}`;

@Component({ 
    templateUrl: 'dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService],
    encapsulation: ViewEncapsulation.None
  
})


export class DashboardComponent implements OnInit {
    public historyType = 'requests';
    public calendarRequestor = 0;
    public userData: object;
    @ViewChild('userForm') public userForm: FormGroup;
    public editSettings: EditSettingsModel;
    public toolbar: ToolbarItems[];
    @ViewChild('scheduleObj')
    public scheduleObj: ScheduleComponent;
    account = this.accountService.accountValue;
    isManager = 0;
    isSA = 0;
    public isEditing = false;
    alertTab = "red";
    public header;
    public currentEvent;
    
    public showQuickInfo = true;
    public scheduleView: View = 'Week';
    public monthEventTemplate = '<div class="e-subject">${Subject} (${status})</div>';
    public agendaEventTemplate = '`<div class="e-subject">${Subject} (${status})<br>${StartTime.toLocaleTimeString()} - ${EndTime.toLocaleTimeString()}</div>';
    public timeScale: TimeScaleModel = { interval: 60, slotCount: 4 };
    //public selectedDate: Date = new Date(2022, 0, 10);
    //public eventSettings: EventSettingsModel = { dataSource: extend([], scheduleData, null, true) as Record<string, any>[] };


    public readonly: boolean = false;
    public selectedDate: Date = new Date();
    private dataManager: DataManager = new DataManager({
      //  url: 'https://ej2services.syncfusion.com/production/web-services/api/Schedule',
        url: `${baseUrl}/requests`,
        adaptor: new ODataV4Adaptor,
        crossDomain: true
    });
    public eventSettings: EventSettingsModel = { dataSource: this.dataManager };
    
    
    
    public unapprovedRequests: Object[];
    public requestHistory: Object[];
    public ledgerHistory: Object[];
    public users: Object[];
    public pageSettings: Object;

    constructor(private accountService: AccountService,
            public dataService: DataService,
            public ptoService: PTOService,
            private configService: ConfigService,
            private route: ActivatedRoute,
            private router: Router,
            public dialog: MatDialog
        ) {
    }

    //public data: string[] = ['Badminton', 'Basketball', 'Cricket', 'Football', 'Golf', 'Hockey', 'Rugby', 'Snooker','Tennis'];

 
    ngOnInit(): void {
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
        this.toolbar = ['Edit'];
        this.pageSettings = { pageCount: 100 };
        this.isSA = this.account.user.role === 'admin' ? 1 : 0;
        /*
        this.accountService.userInfo(this.accountService.accountValue.user.id).pipe(
            map(ui => {
                    this.accountService.accountValue.userinfo = ui;
                    this.isSA = ui.superadmin;

                    this.accountService.employees(this.accountService.accountValue.user.id).pipe(
                        map(emp => {
                                this.accountService.accountValue.employees = emp;
                                this.isManager = this.account.employees.length > 0 ? 1 : 0;
                        }),
                    ).subscribe();
            
                    
            }),
        ).subscribe();
        */

        


        this.dataService.allusers = [];
  
    }

    onTabClick(event) {
        console.log(event);
        console.log(event.tab.textLabel);

        /*
        if (this.isSA) {
            this.ptoService.getRequestsByStatus('new').pipe(
                map(data => {
                    this.unapprovedRequests = data;
                }),
            ).subscribe();
        } else {
            this.ptoService.getRequestsByApproverStatus(this.account.user.id, 'new').pipe(
                map(data => {
                        this.unapprovedRequests = data;
                }),
            ).subscribe();

        }
        */
        if (event.tab.textLabel === 'Calendar') {
            this.dataService.request.requestor = this.account.user.id;
            if (this.isSA) {
                this.accountService.getAll().pipe(
                    map(data => {
                            this.users = data;
                    }),
                ).subscribe();
            } else {
                this.accountService.myemployees(this.account.user.id).pipe(
                    map(data => {
                            this.users = data;
                    }),
                ).subscribe();
            }
            this.scheduleObj.refresh();
        }

        if (event.tab.textLabel === 'History') {
            this.dataService.request.requestor = this.account.user.id;


            if (this.isSA) {
                this.accountService.getAll().pipe(
                    map(data => {
                            this.users = data;
                    }),
                ).subscribe();
            } else {
                this.accountService.myemployees(this.account.user.id).pipe(
                    map(data => {
                            this.users = data;
                    }),
                ).subscribe();
            }


            this.ptoService.getRequestsByRequestor(this.account.user.id).pipe(
                map(data => {
                        this.requestHistory = data;
                }),
            ).subscribe();

            this.ptoService.getLedger(this.account.user.id).pipe(
                map(data => {
                        this.ledgerHistory = data;
                }),
            ).subscribe();
        }

        if (event.tab.textLabel === 'Users') {
                this.accountService.getAll().pipe(
                    map(data => {
                            this.users = data;
                    }),
                ).subscribe();
        }




    }   

    updateCalendarRequestor() {
        debugger;


        if (this.calendarRequestor === 0) {
            this.dataManager.dataSource.url = `${baseUrl}/requests`;
        } else {
            this.dataManager.dataSource.url = `${baseUrl}/requests/user/${this.calendarRequestor}`;
        }
        this.scheduleObj.refresh();
    }

    changeDepartment() {
        debugger;
        this.configService.getSpecialities(this.account.userinfo.department).pipe(
            map(specialties => {
                    this.dataService.specialties = specialties;
            }),
        ).subscribe();        
    }

    changeHistory() {
        this.ptoService.getRequestsByRequestor(this.dataService.request.requestor).pipe(
            map(data => {
                    this.requestHistory = data;
            }),
        ).subscribe();
        this.ptoService.getLedger(this.dataService.request.requestor).pipe(
            map(data => {
                    this.ledgerHistory = data;
            }),
        ).subscribe();
    }

    


    ngOnDestroy(): void {        

    }

    applyCategoryColor(args: EventRenderedArgs): void {
        let categoryColor: string = "blue";

        if (args.data.status=="new") {
            categoryColor = "red";
        }
        if (args.data.status=="denied") {
            categoryColor = "gray";
        }

        if (this.scheduleObj.currentView === 'Agenda') {
            (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
        } else {
            args.element.style.backgroundColor = categoryColor;
        }
    }
 

}
