import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account } from '@app/_models';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '@app/_services/data.service';

const baseUrl = `${environment.apiUrl}`;
const directoryUrl = `${environment.directoryUrl}`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    //httpOptions = {
      //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': 'MTExMTExMTExMTExMTExMTExMTExMTExMTExMTEy' })
    //};

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'x-api-key': 'MTExMTExMTExMTExMTExMTExMTExMTExMTExMTEy'
        })
      };

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private dataService: DataService
    ) {
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${directoryUrl}/login`, { email, password }, this.httpOptions)
            .pipe(map(account => {


                this.accountSubject.next(account);

                //account.user.department = 1;
                //account.user.speciality = 4;

                this.cookieService.delete('5th-auth-token'); // return req.headers["rr-auth-cookie"];
                this.cookieService.delete('5th-refresh-token'); // return req.headers["rr-auth-cookie"];

                this.cookieService.set('5th-auth-token', account.jwtToken, { expires: 2, sameSite: 'Lax' }); // return req.headers["rr-auth-cookie"];
                this.cookieService.set('5th-refresh-token', account.jwtRefreshToken, { expires: 365 }); // return req.headers["rr-auth-cookie"];
                this.startRefreshTokenTimer();
                return account;
            }));
 
    }





    
    logout() {
        var refreshtoken = this. cookieService.get('5th-refresh-token');
        this.http.post<any>(`${directoryUrl}/revoke-token`, {refreshtoken}).subscribe();
        this.stopRefreshTokenTimer();
        this.cookieService.delete('5th-auth-token'); // return req.headers["rr-auth-cookie"];
        this.cookieService.delete('5th-refresh-token'); // return req.headers["rr-auth-cookie"];
        this.accountSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    refreshToken() {

            debugger;
            var refreshtoken = this. cookieService.get('5th-refresh-token');
            
            return this.http.post<any>(`${directoryUrl}/refresh-token`, {refreshtoken}, this.httpOptions )
                .pipe(map((account) => {

                    if (account) {
                        account.userinfo = this.userInfo(account.user.id);
                        this.accountSubject.next(account);
                        //account.user.department = 1;
                        //account.user.speciality = 4;



                        this.startRefreshTokenTimer();
                        return account;
                    } else {
                        this.stopRefreshTokenTimer();
                        this.accountSubject.next(null);
                        this.router.navigate(['/account/login']);
                    }
                }));

    }

    verifyEmail(token: string) {
        return this.http.post(`${directoryUrl}/verify-email`, { token });
    }

    forgotPassword(email: string) {
        return this.http.post(`${directoryUrl}/forgot-password`, { email });
    }

    validateResetToken(token: string) {
        return this.http.post(`${directoryUrl}/validate-reset-token`, { token: token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${directoryUrl}/reset-password`, { token, password, confirmPassword });
    }

    getAll() {
        return this.http.get<Account[]>(`${baseUrl}/users`, this.httpOptions);
    }

    getAllFromDirectory() {
        return this.http.get<Account[]>(`${directoryUrl}/users`, this.httpOptions);
    }

    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/users/${id}`);
    }

    create(params) {
        return this.http.post(`${baseUrl}/users`, params);
    }

    update(id, params) {

        return this.http.put(`${baseUrl}/users/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    updatepto(id, params) {

        return this.http.put(`${baseUrl}/pto/user/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.accountValue.id)
                    this.logout();
            }));
    }



    userInfo(userId) {
        return this.http.get<any>(`${baseUrl}/users/${userId}`, this.httpOptions)
            .pipe();
    }

    employees(userId) {
        return this.http.get<any>(`${directoryUrl}/users/manager/${userId}`, this.httpOptions)
            .pipe();
    }


    myemployees(userId) {
        return this.http.get<any>(`${baseUrl}/users/manager/${userId}`, this.httpOptions)
            .pipe();
    }


    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => { this.refreshToken().subscribe() }, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}