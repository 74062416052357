<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row" style="padding-top: 20px;">
        <div class="logo" class="col-11">
            <svg xmlns="http://www.w3.org/2000/svg" width="261.059" height="20.685" viewBox="0 0 261.059 20.685">
                <g id="_5th_Color_Wordmark" data-name="5th_Color_Wordmark" transform="translate(20 -2.1)">
                    <rect id="Rectangle_498" data-name="Rectangle 498" width="4.119" height="19.777" transform="translate(19.246 2.521)"/>
                    <path id="Path_20818" data-name="Path 20818" d="M133.5,23.777h4.075v-7.53h8.172V13.036h-8.172V7.256h11.693V4H133.5Z" transform="translate(-103.934 -1.479)"/>
                    <path id="Path_20819" data-name="Path 20819" d="M0,23.777H4.075v-7.53h8.172V13.036H4.075V7.256H15.768V4H0Z" transform="translate(0 -1.479)"/>
                    <path id="Path_20820" data-name="Path 20820" d="M222.2,7.256h6.688V23.777h4.075V7.256h6.71V4H222.2Z" transform="translate(-172.99 -1.479)" fill="#ee2d26"/>
                    <path id="Path_20821" data-name="Path 20821" d="M338.23,13.036H328.153V4H324.1V23.777h4.053v-7.53H338.23v7.53H342.3V4H338.23Z" transform="translate(-252.322 -1.479)" fill="#ee2d26"/>
                    <path id="Path_20822" data-name="Path 20822" d="M458.737,17.681a6.663,6.663,0,0,1-4.584,1.683c-3.654,0-6.423-2.946-6.423-6.865s2.768-6.865,6.423-6.865a6.642,6.642,0,0,1,4.363,1.572l.687.576,3.1-2.3L461.24,4.57a10.25,10.25,0,0,0-7.021-2.37c-6.113,0-10.719,4.429-10.719,10.32a10.18,10.18,0,0,0,10.475,10.343,10.319,10.319,0,0,0,7.419-2.7l1.041-.974L459.4,17.1Z" transform="translate(-345.279 -0.078)"/>
                    <path id="Path_20823" data-name="Path 20823" d="M557.807,2.1c-6.179,0-11.007,4.54-11.007,10.32,0,5.891,4.717,10.343,10.963,10.343,6.157,0,10.985-4.54,10.985-10.343C568.725,6.529,564.03,2.1,557.807,2.1Zm0,17.23a6.784,6.784,0,0,1-6.8-6.91,6.686,6.686,0,0,1,6.755-6.932,6.8,6.8,0,0,1,6.8,6.932A6.707,6.707,0,0,1,557.807,19.33Z" transform="translate(-425.702)"/>
                    <path id="Path_20824" data-name="Path 20824" d="M676.075,4H672V23.777h13.288V20.544h-9.213Z" transform="translate(-523.174 -1.479)"/>
                    <path id="Path_20825" data-name="Path 20825" d="M758.829,2.1C752.65,2.1,747.8,6.64,747.8,12.42c0,5.891,4.717,10.343,10.963,10.343,6.179,0,11.007-4.54,11.007-10.343C769.77,6.529,765.074,2.1,758.829,2.1Zm0,17.23a6.784,6.784,0,0,1-6.8-6.91,6.663,6.663,0,0,1,6.71-6.932,6.807,6.807,0,0,1,6.821,6.932A6.649,6.649,0,0,1,758.829,19.33Z" transform="translate(-582.187)"/>
                    <path id="Path_20826" data-name="Path 20826" d="M886.853,16.269a5.779,5.779,0,0,0,3.942-5.78A5.746,5.746,0,0,0,889.156,6.1c-1.373-1.4-3.566-2.1-6.555-2.1h-9.5V23.777h4.053V7.211h5.116c2.724,0,4.429,1.373,4.429,3.61s-1.705,3.676-4.363,3.676h-2.724l6.423,9.279h4.584l-4.939-7.021Z" transform="translate(-679.737 -1.479)"/>
                </g>
            </svg>
        </div>
        <div class="col-1">
            <a class="btn btn-danger" aria-label="Logout"  (click)="accountService.logout()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
            </a>

        </div>
    </div>

    <div class="row justify-content-between align-items-center" style="background-color: white; height: 50px">
        <div class="col-12">
           
            <mat-tab-group backgroundColor="white" class="menuprimary"  color="transparent"  (selectedTabChange)="onTabClick($event)">
                <mat-tab label="Home">
                    <mat-tab-group class="menusecondary" backgroundColor="blueviolet" color="transparent" (selectedTabChange)="onTabClick($event)">
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Personal</span></ng-template>
                            <personalinfo></personalinfo>
                        </mat-tab>
    
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Job</span></ng-template>
                            <job></job>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Time Off</span></ng-template>
                            <timeoff></timeoff>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Benefits</span></ng-template>
                            <benefits></benefits>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Performance</span></ng-template>
                            <performance></performance>
                        </mat-tab>      
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Documents</span></ng-template>
                            <documents></documents>
                        </mat-tab>              
                        
                                            
                    
                    
                    </mat-tab-group>
                </mat-tab> 
                <mat-tab label="Admin">
                    <mat-tab-group class="menusecondary" backgroundColor="blueviolet" color="transparent" (selectedTabChange)="onTabClick($event)">

                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Holidays</span></ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Time Off Requests</span></ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label><span class="mainheader">Performance Reviews</span></ng-template>
                        </mat-tab>      
                        <mat-tab *ngIf="isSA"  label="Users">
                            <ng-template mat-tab-label><span class="mainheader">Users</span></ng-template>
                            
                                <div class="control-section" style="margin-top: 100px">
                                    <ejs-grid #grid [dataSource]='users' [editSettings]='editSettings' [toolbar]='toolbar'>
                                        <e-columns>
                                            <e-column field='email' headerText='Email' width='120' textAlign='Left'></e-column>
                                            <e-column field='firstname' headerText='First Name' width='160'></e-column>
                                            <e-column field='lastname' headerText='Last Name' width='120'></e-column>
                        
                                        </e-columns>
                                    </ejs-grid>
                                
                                </div>
                        
                        
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab> 
                <mat-tab label="Intranet">
                </mat-tab> 
            </mat-tab-group>
        </div>
    </div>
    <div class="row justify-content-between" style="background-color: blueviolet; height: 170px">
        <div class="row profile-header">
            <div class="col-3"></div>
            <div class="col-9">
                <p>{{account.user.firstname}} {{account.user.lastname}}</p>
                <p class="profile-header-smaller">Department</p>
            </div>
        </div>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-2">
                <img src="https://fifthcolor.com/resources/400x500crop15-w400h500.jpg.webp" class="avatar" />
            </div>
            <div class="col-9">
               
            </div>
        </div>
        
    </div>
</div>



