import { Component, Inject } from '@angular/core';
;

@Component({
    selector: 'timeoff',
    templateUrl: 'timeoff.component.html',
    styleUrls: ['./timeoff.component.scss'],
  })
  export class TimeoffComponent {
   
   

    constructor(
    ) { 
   }


}