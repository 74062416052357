import { Component, Inject } from '@angular/core';
;

@Component({
    selector: 'personalinfo',
    templateUrl: 'personalinfo.component.html',
    styleUrls: ['./personalinfo.component.scss'],
  })
  export class PersonalInfoComponent {
   
   

    constructor(
    ) { 
   }


}