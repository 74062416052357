<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-9">
            <svg xmlns="http://www.w3.org/2000/svg" width="261.059" height="20.685" viewBox="20 0 261.059 20.685">
                <g id="_5th_Color_Wordmark" data-name="5th_Color_Wordmark" transform="translate(20 -2.1)">
                  <rect id="Rectangle_498" data-name="Rectangle 498" width="4.119" height="19.777" transform="translate(19.246 2.521)"/>
                  <path id="Path_20818" data-name="Path 20818" d="M133.5,23.777h4.075v-7.53h8.172V13.036h-8.172V7.256h11.693V4H133.5Z" transform="translate(-103.934 -1.479)"/>
                  <path id="Path_20819" data-name="Path 20819" d="M0,23.777H4.075v-7.53h8.172V13.036H4.075V7.256H15.768V4H0Z" transform="translate(0 -1.479)"/>
                  <path id="Path_20820" data-name="Path 20820" d="M222.2,7.256h6.688V23.777h4.075V7.256h6.71V4H222.2Z" transform="translate(-172.99 -1.479)" fill="#ee2d26"/>
                  <path id="Path_20821" data-name="Path 20821" d="M338.23,13.036H328.153V4H324.1V23.777h4.053v-7.53H338.23v7.53H342.3V4H338.23Z" transform="translate(-252.322 -1.479)" fill="#ee2d26"/>
                  <path id="Path_20822" data-name="Path 20822" d="M458.737,17.681a6.663,6.663,0,0,1-4.584,1.683c-3.654,0-6.423-2.946-6.423-6.865s2.768-6.865,6.423-6.865a6.642,6.642,0,0,1,4.363,1.572l.687.576,3.1-2.3L461.24,4.57a10.25,10.25,0,0,0-7.021-2.37c-6.113,0-10.719,4.429-10.719,10.32a10.18,10.18,0,0,0,10.475,10.343,10.319,10.319,0,0,0,7.419-2.7l1.041-.974L459.4,17.1Z" transform="translate(-345.279 -0.078)"/>
                  <path id="Path_20823" data-name="Path 20823" d="M557.807,2.1c-6.179,0-11.007,4.54-11.007,10.32,0,5.891,4.717,10.343,10.963,10.343,6.157,0,10.985-4.54,10.985-10.343C568.725,6.529,564.03,2.1,557.807,2.1Zm0,17.23a6.784,6.784,0,0,1-6.8-6.91,6.686,6.686,0,0,1,6.755-6.932,6.8,6.8,0,0,1,6.8,6.932A6.707,6.707,0,0,1,557.807,19.33Z" transform="translate(-425.702)"/>
                  <path id="Path_20824" data-name="Path 20824" d="M676.075,4H672V23.777h13.288V20.544h-9.213Z" transform="translate(-523.174 -1.479)"/>
                  <path id="Path_20825" data-name="Path 20825" d="M758.829,2.1C752.65,2.1,747.8,6.64,747.8,12.42c0,5.891,4.717,10.343,10.963,10.343,6.179,0,11.007-4.54,11.007-10.343C769.77,6.529,765.074,2.1,758.829,2.1Zm0,17.23a6.784,6.784,0,0,1-6.8-6.91,6.663,6.663,0,0,1,6.71-6.932,6.807,6.807,0,0,1,6.821,6.932A6.649,6.649,0,0,1,758.829,19.33Z" transform="translate(-582.187)"/>
                  <path id="Path_20826" data-name="Path 20826" d="M886.853,16.269a5.779,5.779,0,0,0,3.942-5.78A5.746,5.746,0,0,0,889.156,6.1c-1.373-1.4-3.566-2.1-6.555-2.1h-9.5V23.777h4.053V7.211h5.116c2.724,0,4.429,1.373,4.429,3.61s-1.705,3.676-4.363,3.676h-2.724l6.423,9.279h4.584l-4.939-7.021Z" transform="translate(-679.737 -1.479)"/>
                </g>
            </svg>
            <span style="color: red">{{envMessage}}</span>
        </div>
    </div>
</div>
<hr>
<h5 *ngIf="message===''">Update Profile</h5>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row" *ngIf="message===''">
        <div class="form-group col-5">
            <label>First Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
        </div>
        <div class="form-group col-5">
            <label>Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="message===''">
        <label>Email</label>
        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
        </div>
    </div>
    <h3 class="pt-3">Change Password</h3>
    <p *ngIf="message===''">Leave blank to keep the same password</p>
    <p *ngIf="message!=''" style="color: red">{{message}}</p>
    <div class="form-row">
        <div class="form-group col">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-group col">
            <label>Confirm Password</label>
            <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <button type="submit" [disabled]="loading" class="btn btn-primary r-spacer-5">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Update
        </button>
<!--        <button type="button" (click)="onDelete()" [disabled]="deleting" class="btn btn-danger">
            <span *ngIf="deleting" class="spinner-border spinner-border-sm mr-1"></span>
            Delete
        </button>-->
        <a routerLink="../details" href="" class="btn btn-link">Cancel</a>
    </div>
</form>
