import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { QueryOptions } from '@app/_models/query-opts';


@Injectable()
export class DataService {

    public reviews: any;
    public reviews2: any;

    public departments: any;
    public specialties: any;

    public allusers: any;
    userData: any;
    //public evaluators: any;


    public itemslist: any;
    public vendorslist: any;
    public noteslist: any;
    public item: any;
    public selectedVendor: any;
    public selecteditem: any;
    public selecteditemid: any;
    public selecteditemdescription: any;
    public startDate: any;
    public endDate: any;
    public showSimpleDatePicker: boolean;
    public selectedDates: { startDate: Moment, endDate: Moment };
    public dialogRef: any;


    public confirmationText = 'Text';
    public confirmationTitle = 'Title';

    public request = {
        requestor: 0,
        requestorname: '',
        pto: 0,
        ptoremaining: 0,
        starttime: '09:00 AM',
        endtime: '05:00 PM',
        partial: false,
        startdate: null,
        enddate: null,
        notes: null,
        makeupnotes: null,
        days: 0,
        nopto: 0,
        nopaid: 0,
        paidhours: 0,
        unpaidhours: 0,
        approverid: 0
    };

    
    queryOptions: QueryOptions = new QueryOptions();
    
    public constructor( ) {


    }

    public resetRequest() {
        this.request = {
            requestor: 0,
            requestorname: '',
            pto: 0,
            ptoremaining: 0,
            starttime: '09:00 AM',
            endtime: '05:00 PM',
            partial: false,
            startdate: null,
            enddate: null,
            notes: null,
            makeupnotes: null,
            days: 0,
            nopto: 0,
            nopaid: 0,
            paidhours: 0,
            unpaidhours: 0,
            approverid: 0
        };
    }



}