import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AccountService, AlertService } from '@app/_services';
import { MustMatch } from '@app/_helpers';

@Component({ templateUrl: 'update.component.html' })
export class UpdateComponent implements OnInit {
    account = this.accountService.accountValue;
    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    deleting = false;
    message = '';
    envMessage: string = environment.message;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { 
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
          if (params.force) {
              this.message = 'You must change your password before continuing';
          }
        });


        this.form = this.formBuilder.group({
            firstName: [this.account.user.firstname, Validators.required],
            lastName: [this.account.user.lastname, Validators.required],
            email: [this.account.user.email, [Validators.required, Validators.email]],
            password: ['', [Validators.minLength(6)]],
            confirmPassword: ['']
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        var obj = this.form.value;

        if (obj.password === '') {
            delete obj.password;
        } else {
            obj.forcereset = 0;
            this.account.user.forcereset = 0;
        }

        delete obj.confirmPassword;


        this.loading = true;
        this.accountService.update(this.account.user.id, obj)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.alertService.success('Update successful', { keepAfterRouteChange: true });
                    this.router.navigate(['/'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

  
}