<div *ngIf="loading" class="loading-container flex-content-center">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>


<!-- Profile menu; header? Put this in its own component -->
<div [ngClass]="{'pl-lg-4 container-fluid': account}">
        <div class="row">
            
        
            <div class="col-sm-11 col-xl-11">
                  <div class="sn-btns d-flex align-items-center justify-content-end">
                </div>
            </div>

       

       <!-- <div class="row" *ngIf="dataService.showSimpleDatePicker">
            <div class="col-lg-12">
                <rr-simpledatepicker></rr-simpledatepicker>
            </div>
        </div>-->
    </div>

    <!-- global alerts -->
    <alert></alert>
    
    <router-outlet></router-outlet>

          
    
 
</div>