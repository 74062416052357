import { Component } from '@angular/core';
import { ConfigService } from '../../_services/config.service';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { DataService, ItemsService, AccountService, PTOService } from '@app/_services';
import { map } from 'rxjs/operators';

@Component({
    selector: 'profile',
    templateUrl: 'profile.component.html',
  })
  export class ProfileComponent {
    search = new UntypedFormControl();
    itemsControl = new UntypedFormControl();
    adding = false;
    editing = false;
    item='';
    itemid=0;
    description='';
    errorMsg = "";
    constructor(
        public dataService: DataService,
        private itemsService: ItemsService,
        private accountService: AccountService,
        private configService: ConfigService,
        private ptoService: PTOService
    ) { 
        this.accountService.getAllFromDirectory().pipe(
            map(items => {
                    this.dataService.itemslist = items;
            }),
        ).subscribe();

        this.dataService.userData.ptoremainingprevious = this.dataService.userData.ptoremaining;

        // get the departments
        this.configService.getDepartments().pipe(
        map(departments => {
                this.dataService.departments = departments;

                if (this.dataService.userData.department) {
                    this.configService.getSpecialities(this.dataService.userData.departmentid).pipe(
                        map(specialties => {
                                this.dataService.specialties = specialties;
                        }),
                    ).subscribe();        
                }
        }),
    ).subscribe();

    }

 

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    isSelected(obj) {
        
        const result = this.dataService.allusers.find(({ id }) => id === obj.id);

        console.log(result) // { name: 'cherries', quantity: 5 }

        if (result) {
            obj.selected = true;
          return true;
      } else {
          obj.selected = true;
          return false;
      }
    }

      selectionChange(obj) {
          debugger;
        if (obj.selected) {
            this.dataService.allusers.push({id: obj.value.id, firstname: obj.value.firstname, lastname: obj.value.lastname});
        } else {
            this.removeObj(this.dataService.allusers, obj.value.id);
        } 

      }


    removeObj(arr, id) {
    
        for( var i = 0; i < arr.length; i++){ 
                                       
            if ( arr[i].id === id) { 
                arr.splice(i, 1); 
                i--; 
            }
        }
    }  

    itemSelected(obj) {
        debugger;
        
        //alert(1);

        //this.dataService.selecteditem = obj.item;
        //this.dataService.selecteditemid = obj.id;
        //this.dataService.selecteditemdescription = obj.description;
        //this.dataService.dialogRef.close();
    }

    close() {
        debugger;
        this.dataService.dialogRef.close();
    }

    updateProfile() {

        debugger;

        if (!this.dataService.userData.specialtyid) {
            this.errorMsg = 'Specialty is a required field';
            return false;
        }

        if (this.dataService.userData.pto === null) {
            this.errorMsg = 'PTO used is a required field';
            return false;
        }

        if (this.dataService.userData.ptoremaining === null) {
            this.errorMsg = 'PTO remaining is a required field';
            return false;
        }

        const body = {
            pto: this.dataService.userData.pto,
            ptoremaining: this.dataService.userData.ptoremaining,
        }
        this.accountService.updatepto(this.dataService.userData.userid, body).subscribe();

        const body2 = {
            department: this.dataService.userData.departmentid,
            specialty: this.dataService.userData.specialtyid
        }
        this.accountService.update(this.dataService.userData.userid, body2).subscribe();

        const body3 = {
            userid: this.dataService.userData.userid,
            before: this.dataService.userData.ptoremainingprevious,
            after: this.dataService.userData.ptoremaining,
            activity: 'admin update',
            pto: this.dataService.userData.ptoremaining - this.dataService.userData.ptoremainingprevious

        }
        this.ptoService.addLedger(body3).subscribe();

        this.dataService.dialogRef.close();

    }

    changeDepartment() {
        this.configService.getSpecialities(this.dataService.userData.departmentid).pipe(
            map(specialties => {
                    this.dataService.specialties = specialties;
            }),
        ).subscribe();

        //this.dataService.userData.specialty = null;
        //this.dataService.userData.specialtyid = null;
    }

}