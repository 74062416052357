import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Reviews } from '@app/_models';
import { Review } from '@app/_models';
import { QueryOptions } from '@app/_models';
import { DateRangeService } from './daterange.service';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class PTOService{
    
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'x-api-key': 'MTExMTExMTExMTExMTExMTExMTExMTExMTExMTEy'
        })
    };
    constructor(private httpClient: HttpClient, private dateRangeService: DateRangeService){ }

    getRequest(id){
        var url = `${baseUrl}/request/${id}`;

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    addRequest(body){
        var url = `${baseUrl}/request`;

        return this.httpClient
            .post<any>(url, body)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    updateRequest(id, body){
        var url = `${baseUrl}/request/${id}`;

        return this.httpClient
            .put<any>(url, body)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    updatePTO(id, body){
        var url = `${baseUrl}/pto/user/${id}`;

        return this.httpClient
            .put<any>(url, body)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getRequestsByApproverStatus(approver, status){
        var url = `${baseUrl}/requests/approver/${approver}/status/${status}`;

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getRequestsByStatus(status){
        var url = `${baseUrl}/requests/status/${status}`;

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }
  
    getRequestsByRequestor(id){
        var url = `${baseUrl}/requests/user/${id}`;

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getLedger(id){
        var url = `${baseUrl}/ledger/user/${id}`;

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    addLedger(body){
        var url = `${baseUrl}/ledger`;

        return this.httpClient
            .post<any>(url, body)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

}