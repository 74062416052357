import { NgModule, APP_INITIALIZER, OnInit } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { appInitializer } from './_helpers';
import { JwtInterceptor, ErrorInterceptor } from './_interceptors';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { PicklistComponent } from './_components';
import { NamelistComponent } from './_components';
import { ManagerlistComponent } from './_components';
import { ProfileComponent } from './_components';
import { PersonalInfoComponent } from './_components';
import { JobComponent } from './_components';
import { TimeoffComponent } from './_components';
import { BenefitsComponent } from './_components';
import { ConfirmationComponent } from './_components';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AuthInterceptor } from './_interceptors';
import { LoadingInterceptor } from './_interceptors';
import { CommonModule } from '@angular/common';
import { DataService } from './_services/data.service';;
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DashboardComponent } from './home/dashboard';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';


import { NgxTypeaheadModule } from 'ngx-typeahead';

import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppBarModule } from '@syncfusion/ej2-angular-navigations';
import { GridModule, EditService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { ScheduleModule, RecurrenceEditorModule, DayService, WeekService, WorkWeekService, MonthService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';

// Import from library
import {
    NgxAwesomePopupModule,
    DialogConfigModule,
    ConfirmBoxConfigModule,
    ToastNotificationConfigModule
 } from '@costlydeveloper/ngx-awesome-popup';
//import { MatRadioButton } from '@angular/material/radio';


@NgModule({

    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'Report-Rover-Cookie',
            headerName: 'Report-Rover-Header'
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        MatSliderModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatSnackBarModule,
        MatListModule,
        MatCheckboxModule,
        MatRadioModule,
        MatInputModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        NgxTypeaheadModule,
        NgxDaterangepickerMd.forRoot(),
        NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
        DialogConfigModule.forRoot(), // Needed for instantiating dynamic components.
        ConfirmBoxConfigModule.forRoot(), // Needed for instantiating confirm boxes.
        ToastNotificationConfigModule.forRoot(), // Needed for instantiating toast notifications.
        GridModule,
        DropDownListModule,
        AppBarModule,
        ScheduleModule, RecurrenceEditorModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        DashboardComponent,
        PicklistComponent,
        NamelistComponent,
        ProfileComponent,
        PersonalInfoComponent,
        JobComponent,
        TimeoffComponent,
        BenefitsComponent,
        ConfirmationComponent,
        ManagerlistComponent

    ],

    providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DataService,
        EditService,
        ToolbarService,
        [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}],


    ],
    bootstrap: [AppComponent]
})
export class AppModule { }