import { Component, Inject } from '@angular/core';
;

@Component({
    selector: 'job',
    templateUrl: 'job.component.html',
    styleUrls: ['./job.component.scss'],
  })
  export class JobComponent {
   
   

    constructor(
    ) { 
   }


}